import React, { useState } from 'react';
import { TextField, Button, Container, FormControl, CircularProgress, FormHelperText } from '@material-ui/core';
import { isAuthenticated, setToken } from '../assets/js/auth';
import { useRouter } from 'next/router';

const Login: React.FC = () => {
  const router = useRouter();

  const [loading, setLoading] = useState<boolean>(false);
  const [username, setUsername] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [error, setError] = useState<string>('');
  const [errors, setErrors] = useState<Record<string, string>>({ username: '', password: '' });

  const handleUsername = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUsername(e.target.value);
  };

  const handlePassword = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Reset errors
    setError("");
    setErrors({ username: '', password: '' });

    const formErrors = {
      username: "",
      password: ""
    }

    if (username === '') {
      formErrors.username = "Username must be filled";
    }

    if (password === '') {
      formErrors.password = "Password must be filled";
    }

    setErrors(formErrors);

    if (formErrors.username === '' && formErrors.password == '') {
      setLoading(true);

      try {
        const authentication = await fetch(process.env.NEXT_PUBLIC_API + '/auth', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ username, password })
        });
        if (authentication.status === 200) {
          const body = await authentication.json();
          const token = body.token;
          setToken(token);

          if (isAuthenticated()) {
            await router.push('/');
          }
        } else {
          setError('Something went wrong!');
        }
      } catch (error) {
        setError('Invalid username or password. Please try again.');
      }

      setLoading(false);
    }
  };

  return (
    <div id="login">
      <Container maxWidth="sm" className="container">
        <h2>Kirjaudu sisään</h2>
        <form className="form" onSubmit={ handleSubmit } noValidate>
          <FormControl fullWidth={ true } className="form-field">
            <TextField
              error={ errors.username !== '' }
              label="Käyttäjätunnus"
              id="username"
              type="text"
              variant="outlined"
              helperText={ errors.username }
              onChange={ handleUsername }
              required
            /></FormControl>
          <FormControl fullWidth={ true } className="form-field">
            <TextField
              error={ errors.password !== '' }
              label="Salasana"
              id="password"
              type="password"
              helperText={ errors.password }
              variant="outlined"
              required
              onChange={ handlePassword }
            />
          </FormControl>
          { error ? <FormHelperText error={ true }>{ error }</FormHelperText> : '' }
          <Button type="submit" color="primary" variant="contained" className="sign-in" disabled={ loading }>
            { loading ? <CircularProgress /> : `Kirjaudu sisään` }
          </Button>
        </form>
      </Container>
    </div>
  );
};

export default Login;